import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Анхдагч зах зээлийн арилжааны захиалга хэрхэн хийх вэ?",
  "description": null,
  "author": "OTC help",
  "category": "market",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Анхдагч зах зээлийн арилжааны захиалга хэрхэн хийх вэ?`}</h2>
    <ol>
      <li parentName="ol">{`Үндсэн цэснээс `}<strong parentName="li">{`[Market]`}{`-`}{`[Primary]`}</strong>{` хэсгийг сонгоно`}</li>
      <li parentName="ol">{`[Open Offers]`}{` хэсгээс захиалга хийх үнэт цаасыг сонгон дэлгэрэнгүй мэдээлэл бүхий хуудаснаас `}{`[Make offer]`}{` хэсэг дэх `}<strong parentName="li">{`[Buy]`}</strong>{` ногоон товчийг дарна.`}</li>
      <li parentName="ol">{`Захиалга өгөх хэсэгт үнэт цаасны нэр, симбол, нэгж үнэ, захиалга хаагдах хугацаа зэрэг хураангуй мэдээлэл харагдах бөгөөд захиалга өгөх үнэт цаас мөн эсэхийг шалгасны дараа захиалгын мэдээллүүдийг дараах байдлаар оруулна.`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Client ID]`}</strong>{` - хэсэгт захиалга хийж буй харилцагчийн ҮЦТХТ дэх бүртгэлийн дугаарыг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Quantity]`}</strong>{` - хэсэгт захиалгаа өгөх үнэт цаасны тоо ширхгийг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Broker fee]`}</strong>{` - хэсэгт брокерын төлбөрийн хэлбэр (тогтмол дүн/хувь) болон дүнг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Custodian]`}</strong>{` - хэсэгт тухайн үнэт цаасны бүртгэл, төлбөр тооцоо болон хадгалалт хийгдэж буй кастодианыг жагсаалтаас сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Payment type]`}</strong>{` - хэсэгт үнэт цаасны төлбөр тооцоонийй хэлбэрийг жагсаалтаас сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Fee info]`}</strong>{` - хэсэгт тухайн захиалгын төлбөр, хураамжийг тооцон хураангуйлан харуулах бол `}{`[Amount info]`}{` хэсгээс захиалгын нийт хэмжээ болон дүнг харах боломжтой`}</li>
        </ul>
      </li>
    </ol>
    <p>{`Дээрх захиалгын мэдээллээ нягталж шалган `}<strong parentName="p">{`[Submit]`}</strong>{` товчийг дарж захиалгыг амжилттай илгээнэ.`}</p>
    <p>{`Оруулсан захиалгын мэдээллийг бүхэлд нь арилгах бол `}<strong parentName="p">{`[Reset]`}</strong>{` товчийг дарж шинээр оруулна.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Захиалгыг амжилттай илгээснээр `}<strong parentName="li">{`[Market]`}{`-`}{`[Primary]`}</strong>{` хэсэг дэх тухайн үнэт цаасны дэлгэрэнгүй мэдээллийн хуудасны `}<strong parentName="li">{`[My Orders]`}</strong>{` хэсэгт захиалгын мэдээлэл харагдана.`}</li>
      <li parentName="ol">{`Захиалгын мэдээллийг өөрчлөх бол `}<strong parentName="li">{`[Update Order]`}</strong>{` товчийг дарж мэдээллийг засварлан `}<strong parentName="li">{`[Update]`}</strong>{` товчийг дарна`}</li>
      <li parentName="ol">{`Захиалгыг цуцлах бол `}<strong parentName="li">{`[Cancel]`}</strong>{` товчийг дарснаар тухайн захиалга `}<strong parentName="li">{`[My Order]`}</strong>{` хэсгээс устах болно.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      